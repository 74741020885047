<template>
  <template v-for="item in SchedulerItems" :key="item.id">
    <div class="scheduler-list-item grid-x" :class="{ active: checkedIn(item.enrolledMembers)}">

      <div class="cell grid-x">
        <div class="auto cell">
          <p class="ck-overflow-ellipsis" style="margin-left:3px;margin-top:1px">
            <i class="fa-light fa-whistle"></i>
            {{ item.coursetext }}
          </p>
          <p class="ck-overflow-ellipsis" style="margin-left:3px">
            <i class="fa-light fa-calendar-clock"></i>
            Datum: {{ formatDateTs(item.startTs*1000) }}
          </p>
        </div>
        <div class="shrink cell">
          <input type="button" value="Mehr Info" class="cx-button tiny" style="margin-right:2px;margin-top:3px" @click="toggleDesc(item.id)"/> <!--Toggle extra info about each course-->
        </div>
      </div>
      <div :id="'details'+item.id" style="display:none">
        <p class="ck-overflow-ellipsis" style="margin-left:3px">
          <i class="fa-light fa-location-dot"></i>
          Standort: {{ item.locationtext }} 
        </p>
        <p class="ck-overflow-ellipsis" style="margin-left:3px"> 
          <i class="fa-light fa-user"></i>
          Trainer: {{ item.trainertext }}
        </p>
        <p class="ck-overflow-ellipsis" style="margin-left:3px">
          <a>
            <i class="fa-light fa-users"></i>
            Teilnehmer: {{  item.enrolledMembers.length }} / {{ item.enrolledMax }}
          </a>
        </p>
      </div>
    </div>

    <div>
      <div v-if="!checkedIn(item.enrolledMembers)">
        <button @click="checkinCourse(item.id)" style="margin-left:5px;margin-bottom:3px" 
          :style="{color: !canCheckin(item.enrolledMembers.length, item.enrolledMax, item.startTs) ? 'grey' : ''}"
          class="cx-button tiny" 
          :disabled="!canCheckin(item.enrolledMembers.length, item.enrolledMax, item.startTs)"
          :readOnly="!canCheckin(item.enrolledMembers.length, item.enrolledMax, item.startTs)"
        >
          <i class="fa-solid fa-right-to-bracket"></i> 
          Anmelden 
        </button>
      </div> <!-- Add functionality for checkin/checkout -->
      <div v-else>
        <button @click="checkoutCourse(item.id)" style="margin-left:5px;margin-bottom:3px" class="cx-button tiny"
          :disabled="!canCheckout(item.deregisterLatest, item.startTs)"
          :readOnly="!canCheckout(item.deregisterLatest, item.startTs)"
          :style="{color: !canCheckout(item.deregisterLatest, item.startTs) ? 'grey' : ''}" 
        >
          <i class="fa-solid fa-right-to-bracket"></i>
          Abmelden 
        </button>
      </div>
    </div>
    <!-- shrink cell -->
    <!-- grid-x -->
  </template>
  <!-- template v-for -->

  <!-- bottomspace or if no-content -->
  &nbsp;
  <!-- bottomspace or if no-content -->

</template>

<script>

let currentUser;
let SchedulerItems = ref([]);
let timestamp;
let dateFilter = ref(Date.now());

import $ from 'jquery';
import Foundation from 'foundation-sites';

import { ref } from 'vue';

import auth from "../auth";
import notify from 'devextreme/ui/notify';
import { 
  apihost, 
} from "../api";
//import { DxDateBox } from 'devextreme-vue';
//console.log(apihost);

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth

export default {
  name: 'training-data',

  props: {
    templateData: Object,
    date : String,
    place : String,
  },

  components: {
  },

  mounted() {

    this.reveal = new Foundation.Reveal($('#revealDialogCourse'), {
      // These options can be declarative using the data attributes
      animationIn: 'scale-in-up',
    });

    //timestamp
    this.timestamp = this.printTimestamp();

    //DATA Scheduler
    // let objString = '?' + 'customerid='+this.templateData.id;
    // objString = objString + '&filtertype=1';
    // objString = objString + '&filterstart='+this.timestamp;
    //console.log(objString);

    //let objString = '?' + 'date=' + this.date + '&place=' + this.place 

    fetch(apihost+'/'+currentUser.Language+
      `/vue/scheduler/getscheduler/id/action?filtertype=1&filterstart=${this.getDayOfTs(dateFilter.value) / 1000}`)
      .then(handleErrors)
      .then(response => response.text())
      .then(async result => {
        const data = JSON.parse(result);
        SchedulerItems.value = data;
        for(let i = 0; i < SchedulerItems.value.length; i++) {
          SchedulerItems.value[i].coursetext = (await this.fetchCourseData(SchedulerItems.value[i].courseid)).Subject;
        }
      })
      .catch((e) =>  {notify("Kurse nicht verfügbar", "error", 3000); console.log(e)});
  },

  data() {
    return {
      apihost,
      msg: 'Course (part)',
      currentUser,
      timestamp,
      SchedulerItems,
      dateFilter,
    };
  },

  methods: {

    // Added the below openReveal method for two reasons:
    // 1) There was a bug preventing the reveal from working once
    // you navigated away and back to the reveal component.
    // 2) Most dialogs will need to be opened using code.

    openReveal(id) {
      const revealhtml = document.getElementById("revealHtml");
      const revealcontent = document.getElementById('revealContent'+id);
      const revealhtmlcontent = revealcontent.innerHTML;

      //Set Content before open
      revealhtml.innerHTML = revealhtmlcontent;

      //Open Reveal
      //console.log(this.reveal);
      this.reveal.open();

    },

    // checkCourse(action, itemid, customerid) {
    // // console.log(action);
    // // console.log(itemid);
    // // console.log(customerid);

    // //let objString
    // let objString = '?' + 'customerid='+customerid;
    // objString = objString + '&filtertype=1';
    // objString = objString + '&filterstart='+this.timestamp;
    // //console.log(objString);

    // //Scheduler
    // // fetch(apihost+'/'+currentUser.Language+'/vue/scheduler/getscheduler/'+itemid+'/'+action+objString)
    // //   .then(handleErrors)
    // //   .then(response => response.text())
    // //   .then(result => {
    // //   const data = JSON.parse(result);
    // //   //console.log(data);
    // //   this.SchedulerItems = data;
    // // })
    // // .catch(() => { throw 'Network error' });

    // },


    formatDateTs(ts) {
      //console.log(ts);
      const timestamp = parseInt(ts); // timestamp
      const date = new Date(timestamp);
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

      // Get Hours part from the timestamp
      var hours = '0' + date.getHours();
      hours = hours.slice(-2);
      //console.log(hours);

      // Get Minutes part from the timestamp
      var minutes = '0' + date.getMinutes();
      minutes = minutes.slice(-2);
      //console.log(minutes);

      const formattedDate = new Intl.DateTimeFormat('de-DE', options).format(date) + ', ' + hours+':'+minutes;
      //console.log(formattedDate);

      return formattedDate;
    },

    printTimestamp: function () {
      return Date.now();
    },

    toggleDesc(itemid){
      let curr = document.getElementById("details" + itemid).style.display;
      document.getElementById("details" + itemid).style.display = (curr === "none") ? 'block' : 'none';
    },

    checkinCourse(id) {
      fetch(apihost+'/'+currentUser.Language+
        `/vue/scheduler/getscheduler/${id}/checkin?customerid=${currentUser.id}&filtertype=1&filterstart=${this.getDayOfTs(dateFilter.value) / 1000}`)
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result);
          SchedulerItems.value = data;
        })
        .catch(() => notify("Kurse nicht verfügbar", "error", 3000));
    }, 

    checkoutCourse(id) {
      fetch(apihost+'/'+currentUser.Language+
        `/vue/scheduler/getscheduler/${id}/checkout?customerid=${currentUser.id}&filtertype=1&filterstart=${this.getDayOfTs(dateFilter.value) / 1000}`)
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result);
          SchedulerItems.value = data;
        })
        .catch(() => notify("Kurse nicht verfügbar", "error", 3000));
    },

    checkedIn(enrolledMembers) {
      return enrolledMembers.find((e) => e.memberId == currentUser.id) != undefined;
    },

    canCheckin(enrolledCount, enrolledMax, startTs) {
      return (enrolledCount < enrolledMax) && (startTs > Date.now() / 1000); 
    },

    canCheckout(deregisterLatest, startTs) {
      return Date.now() / 1000 < (startTs - deregisterLatest * 60);
    },

    dateFilterChange(){
      fetch(apihost+'/'+currentUser.Language+
        `/vue/scheduler/getscheduler/id/action?filtertype=1&filterstart=${this.getDayOfTs(dateFilter.value) / 1000}`)
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result);
          SchedulerItems.value = data;
        })
        .catch(() => notify("Kurse nicht verfügbar", "error", 3000));
    },

    async fetchCourseData(id) {
      if(id == undefined) { return { Subject: "empty"}; }
      const res = await fetch(`${apihost}/vue/course/getcourse/${id}/get`);
      const obj = await res.json();
      return obj;
    },

    getDayOfTs(ts) {
      let time = new Date(ts);
      time.setHours(0, 0, 0);
      return time.valueOf();
    },
  },

};


</script>
