<template>
	<div class="-userblock" style="text-align: left;">
	
		<template v-if="templateModel.appointmentData.typeid == 1 && dataFetched">
      <div class="grid-x" style="border-bottom: 1px solid #D3D3D3; margin-bottom: 5px">
        <div class="auto cell">
          <p>Teilnehmer: {{ enrolledMembers.length }} / {{ templateModel.appointmentData.enrolledMax }}</p>
        </div>
      </div>
			<template v-for="data in enrolledMembers" :key="data.memberId" >
				<div class="grid-x align-middle" style="padding-bottom: 5px; margin-bottom: 5px; border-bottom: 1px solid #D3D3D3">
					<div class="shrink cell">
						<div class="user-thumb" style="margin-right: 5px;"
						v-bind:style="{ 'background-image': 'url(' + data.member.Avatar + ')' }" >
						</div>
					</div>
					<div class="auto cell">
						<p class="ck-overflow-ellipsis" style="margin: 0;">{{ data.member.FirstName }} {{ data.member.LastName }}</p>
					</div>
				</div><!-- grid-x -->
			</template>
		</template>
		
	</div>
</template>

<script>

import { apihost } from '@/api';
import { ref } from 'vue';

let timestamp;
let enrolledMembers = ref([]);
let dataFetched = ref(false);

export default {

	name: 'appointment-tooltip-template',
	
	props: {
	templateModel: Object,
	},

  async mounted() {

    //timestamp
    this.timestamp = this.printTimestamp();
    enrolledMembers.value = this.templateModel.appointmentData.enrolledMembers;
    
    for(let i = 0; i < enrolledMembers.value.length; i++){
      enrolledMembers.value[i].member = await this.getUserData(enrolledMembers.value[i].memberId);
    }

    dataFetched.value = true;
  },

  unmounted() {
    dataFetched.value = false;
  },


  data() {
    return {
      timestamp,
      enrolledMembers,
      dataFetched,
    };
  },

  methods: {
	
    printTimestamp: function () {
      return Date.now();
    },

    async getUserData(id) {
      const response = await fetch(`${apihost}/vue/member/getmember/${id}/get`);
      const obj = await response.json();
      return obj;
    },
  },


}

</script>
