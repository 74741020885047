<template>

  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">

    <DxPopup
      v-model:visible="shiftPopupVisible"
      :height="500"
      :width="600"
      :drag-enabled="false"
      title="Schicht erstellen"
      @initialized="(e) => e.component.option('toolbarItems', getShiftPopupToolbar())"
      v-if="dataLoadedBarrier[3]"
    >
      <DxForm
        @initialized="(e) => setupShiftForm(e.component)" 
        v-model:form-data="shiftFormData"
      >
      </DxForm>
    </DxPopup>

    <div class="grid-x grid-margin-x align-middle" style="margin-bottom: 15px;">
      <div class="shrink cell">
        <h2>
          <i class="dx-icon nav-icon fa-light fa-calendar-day"></i> 
          {{ title }}
        </h2>
      </div><!-- shrink cell -->

      <div class="auto cell"></div>

      <div class="shrink cell">
        <button class="cx-button medium" style="font-size: 15px" @click="addShift"> + Schicht</button>
      </div>

      <div class="shrink cell" style="width: 200px;">
        <DxSelectBox
          v-model:items="trainerData"
          displayExpr="FirstName"
          valueExpr="id"
          placeholder="Mitarbeiter"
          v-model:value="employeeFilter"
          @value-changed="onFilterChanged"
        />
      </div>

      <div class="shrink cell" style="width: 200px;">
        <DxSelectBox
          v-model:items="typeData"
          displayExpr="text"
          valueExpr="id"
          placeholder="Typ"
          v-model:value="typeFilter"
          @value-changed="onFilterChanged"
        />
      </div><!-- shrink cell -->

      <div class="shrink cell" style="width: 200px;">
        <DxSelectBox
          v-model:items="locationData"
          displayExpr="locationname"
          valueExpr="locationid"
          placeholder="Standort"
          v-model:value="locationFilter"
          @value-changed="onFilterChanged"
        />
      </div><!-- shrink cell -->
    </div><!-- grid-x -->

    <div class="dx-card no-paddings">
      <div style="position: relative">
        <div style="z-index: 10; position: absolute; left: 50%; top: 50%;">
          <DxLoadIndicator
            :visible="dataLoadedBarrier.includes(false)"
          />
        </div>
        <DxScheduler
          id="schedulerId"
          v-if="schedulerVif[0]"
          time-zone="Europe/Berlin"
          v-model:current-view="currentView"
          v-model:selected-cell-data="selectedCells"
          :disabled="dataLoaded()"
          :data-source="schedulerItems"
          :current-date="currentDate"
          :views="views"
          :start-day-hour="6"
          :end-day-hour="24"
          :cell-duration="60"
          :show-all-day-panel="false"
          :first-day-of-week="1"

          @appointment-added="showAddedToast"
          @appointment-deleted="showDeletedToast"
          @appointment-updated="onAppointmentUpdated"
          @appointment-form-opening="onAppointmentFormOpening"
          @cell-context-menu="openCellContextMenu"
          @appointment-context-menu="openAppointmentContextMenu"
          @initialized="(e) => schedulerObject = e.component"
          @appointment-tooltip-showing="(e) => { if(e.appointments[0].appointmentData.typeid != 1) { e.cancel = true; } }"

          appointment-template="AppointmentTemplateSlot"
          appointment-tooltip-template="AppointmentTooltipTemplateSlot"
          data-cell-template="DataCellTemplate"
        >

          <template #DataCellTemplate="{ data }">
            <div class="grid-y" style="height: 50px" v-show="dataLoadedBarrier[4] && currentView != 'month' && typeFilter == 2">
              <!-- xx:00-xx:15 -->
              <div class="large-3 cell" :style="paintDataCell(data.startDate, data.endDate, 0)">
                &nbsp;
              </div>
              <!-- xx:15-xx:30 -->
              <div class="large-3 cell" :style="paintDataCell(data.startDate, data.endDate, 1)">
                &nbsp; 
              </div>
              <!-- xx:30-xx:45 -->
              <div class="large-3 cell" :style="paintDataCell(data.startDate, data.endDate, 2)">
                &nbsp; 
              </div>
              <!-- xx:45-xx:00 -->
              <div class="large-3 cell" :style="paintDataCell(data.startDate, data.endDate, 3)">
                &nbsp; 
              </div>
            </div>
          </template>


          <template #AppointmentTemplateSlot="{ data }">
            <AppointmentTemplate
              :template-model="data"
            />
          </template>


          <template #AppointmentTooltipTemplateSlot="{ data }">
            <AppointmentTooltipTemplate
              :template-model="data"
            />
          </template>

        </DxScheduler>
      </div>

      <DxContextMenu
        v-if="cellContextMenuState.visible"
        v-model:items="cellContextMenuState.items"
        v-model:visible="cellContextMenuState.visible"
        @item-click="contextClickDataCell"
        @hiding="hideContext"
      />

      <DxContextMenu
        v-if="appointmentContextMenuState.visible"
        v-model:items="appointmentContextMenuState.items"
        v-model:visible="appointmentContextMenuState.visible"
        @item-click="contextClickAppointment"
        @hiding="hideContext"
      />

    </div><!-- dx-card -->

  </div><!-- content-block -->

  <div v-else class="content-block">
    Oh no 😢
  </div><!-- content-block -->

</template>

<script>


import DxScheduler from 'devextreme-vue/scheduler';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
import { DxPopup, DxForm } from 'devextreme-vue';
import { nextTick, ref } from 'vue';

import AppointmentTemplate from '../parts/appointment-template.vue';
import AppointmentTooltipTemplate from '../parts/appointment-tooltip-template.vue';
import { RRule, rrulestr } from 'rrule';
import DxContextMenu from 'devextreme-vue/context-menu';

import notify from 'devextreme/ui/notify';
import auth from "../auth";
import { apihost } from "../api";

let schedulerObject = ref(undefined);
let selectedCells = ref([]);

let typeFilter = ref(0);
let locationFilter = ref(0);
let employeeFilter = ref(0);

let currentUser;
let schedulerItems;
let typeData = ref([{ id: 0, text: "Alle Typen" }]); 
let locationData = ref([{ locationid: 0, locationname: "Alle Standorte" }]);
let courseData = ref([]);
let trainerData = ref([{ id: 0, FirstName: "Alle Mitarbeiter" }]);
let trainerDataLoaded = ref(false);

let formInitialized = false;
let shiftFormVisible = ref(true);

let shiftPopupVisible = ref(false);
let currentView = ref("week");

let shiftData = [];

let schedulerVif = ref([true, false]);

let targetedStartDate;

/** schedulerItems, typeData, courseData, trainerData, shiftData **/
let dataLoadedBarrier = ref([false, false, false, false, false]);

let shiftFormData = ref({
  startDate: new Date().setHours(7, 0, 0, 0), 
  endDate: new Date().setHours(8, 0, 0, 0), 
  color: "#ff0000" 
});
let shiftFormUpdate = false;

let cellContextMenuState = ref({ 
  items: [
    { text: "Schicht Löschen", visible: false },
    { text: "Schicht Bearbeiten", visible: false },
    { text: "Termin erstellen", visible: false },
    { text: "Schicht erstellen", visible: false },
  ], 
  cellData: undefined, 
  visible: false, 
  shift: undefined,
});

let appointmentContextMenuState = ref({
  items: [
    { text: "Termin Löschen", visible: false },
    { text: "Termin Serie Löschen", visible: false },
    { text: "Termin Bearbeiten", visible: false },
    { text: "Termin Serie Bearbeiten", visible: false },
  ],
  visible: false,
  appointment: undefined,
  appointmentTarget: undefined,
});

const views = ['day','week','month'];
const frequencies = [{id: 0, text: "Einmalig"}, {id: 1, text: "Wöchentlich"}, {id: 2, text: "Monatlich"}];

const currentDate = new Date();

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
    //locale(currentUser.Language);
  } // e.data
}); //auth

function shiftFormCheckErrors(data) {
  const isDataComplete = data.employeeid != undefined && data.startDate != undefined && data.endDate != undefined;
  if(!isDataComplete) { 
    return "Alle Felder müssen ausgefüllt werden."; 
  }

  const startDate = new Date(data.startDate);
  const endDate = new Date(data.endDate);
  const isDiffPos = endDate.valueOf() - startDate.valueOf() > 0;
  if(!isDiffPos){
    return "Ende muss nach Start gesetzt werden.";
  }

  const isSameDay = startDate.getFullYear() == endDate.getFullYear() &&
                    startDate.getMonth() == endDate.getMonth() &&
                    startDate.getDate() == endDate.getDate();
  if(!isSameDay) {
    return "Start und Ende müssen am selben Tag sein.";
  }

  return "";
}

/**
  * @param {Date} startCell
  * @param {Date} endCell
**/
function getShiftByCell(startCell, endCell) {
  for(const shift of shiftData) {
    const startShift = new Date(shift.startts * 1000);
    const endShift = new Date(shift.endts * 1000);
    for(let i = 0; i < 4; i++) {
      let startCellAdj = new Date(startCell);
      let endCellAdj = new Date(endCell);
      switch(i){
        case 0:
          endCellAdj.setHours(endCellAdj.getHours() - 1, 15);
          break;
        case 1:
          startCellAdj.setMinutes(15);
          endCellAdj.setHours(endCellAdj.getHours() - 1, 30);
          break; 
        case 2:
          startCellAdj.setMinutes(30);
          endCellAdj.setHours(endCellAdj.getHours() - 1, 45);
          break;
        case 3:
          startCellAdj.setMinutes(45);
          break;
      }   
      if(startShift.valueOf() <= startCellAdj.valueOf() && endShift.valueOf() >= endCellAdj.valueOf()){
         return shift;
      }
    }
  }

  return undefined;
}

function submitShiftForm(){
  let data = shiftFormData.value;
  let message = shiftFormCheckErrors(data);
 
  if(message.length > 0) {
    notify({
        message: message,
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'error', 3000); 
    return; 
  }

  const startts = new Date(data.startDate).valueOf() / 1000;
  const endts = new Date(data.endDate).valueOf() / 1000;
  const params = `?employeeid=${data.employeeid}&startts=${startts}&endts=${endts}&color=${Number('0x' + data.color.slice(1))}`;
  const action = shiftFormUpdate ? 'update' : 'insert';
  const id = shiftFormUpdate ? cellContextMenuState.value.shift.id : 0;
  fetch(`${apihost}/vue/shift/getshift/${id}/${action}${params}`)
    .then(response => response.json())
    .then(result => {
      if(employeeFilter.value == data.employeeid){ 

        if(shiftFormUpdate) {
          const ind = shiftData.findIndex((s) => s.id == id);
          shiftData[ind] = result;
        } else {
          shiftData.push(result);
        }
        dataLoadedBarrier.value[4] = false;
        nextTick().then(() => dataLoadedBarrier.value[4] = true);
      }

      notify({
        message: shiftFormUpdate ? "Schicht wurde erfolgreich erstellt." : "Schicht wurde erfolgreich bearbeitet." ,
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'success', 3000);
      shiftPopupVisible.value = false;
      shiftFormData.value = { employeeid: employeeFilter.value, startDate: new Date().setHours(7, 0), endDate: new Date().setHours(8, 0), color: "#ff0000" };
      shiftFormUpdate = false;
    });
}

function formFieldDataChanged(ev) {
  let oldItems = ev.component.option("items");
  if(ev.dataField == "typeid"){
    if(formInitialized) {
      oldItems[5].label.text = ev.value == 1 ? "Trainer" : "Mitarbeiter";
      oldItems[6].visible = ev.value == 1;

      oldItems[3].editorOptions.value = ev.value; 

      ev.component.option("items", oldItems);
    }
  } else if(ev.dataField == "recurrence"){
    if(formInitialized){
      oldItems[8].editorOptions.disabled = ev.value != 1 && ev.value != 2;
      oldItems[7].editorOptions.value = ev.value;
      ev.component.option("items", oldItems);
    }
  }
}

/**
 * @param {DxForm} form
**/
function setupSchedulerForm(form){
  if(form.option("onFieldDataChanged") == null) { 
    form.option("onFieldDataChanged", formFieldDataChanged); 
  }
}

/**
 * @param {DxPopup} popup
**/
function setupSchedulerPopup(popup){
  if(popup.option("onShown") == null) { 
    popup.option("onShown", () => formInitialized = true); 
    popup.option("height", 800);
    popup.option("width", 1000);
    // not sure why this is needed too, but apparently it is needed for some reason.
    popup.option("maxWidth", 1000);
    popup.option("minWidth", 1000);

  }

  // change toolbar
  let toolbarItems = popup.option("toolbarItems");
  toolbarItems[0].options.text = "Speichern";
  toolbarItems[1].options = { text: "Abbrechen" };
  popup.option("toolbarItems", toolbarItems);
}

function getFormItemsArray(appointmentData) {
  const trainerItems = trainerData.value.slice(1);
  const locationItems = locationData.value.slice(1);
  const typeItems = typeData.value.slice(1);

  return [
    {
      colSpan: 2,
      label: {
        text: 'Titel',
      },
      name: 'text',
      dataField: 'text',
      editorType: 'dxTextBox',
      editorOptions: {
        value: appointmentData.text ?? "Neuer Termin",
        readOnly: false,
      },
    }, 
    {
      colSpan: 1,
      label: {
        text: 'Start',
      },
      name: 'startDate',
      dataField: 'startDate',
      editorType: 'dxDateBox',
      editorOptions: {
        type: 'datetime',
        readOnly: false,
      },
    },     
    {
      colSpan: 1,
      label: {
        text: 'Ende',
      },
      name: 'endDate',
      dataField: 'endDate',
      editorType: 'dxDateBox',
      editorOptions: {
        type: 'datetime',
        readOnly: false,
      },
    },     
    {
      colSpan: 1,
      label: {
        text: 'Typ',
      },
      name: 'typeid',
      dataField: 'typeid',
      editorType: 'dxSelectBox',
      editorOptions: {
        value: appointmentData.typeid ?? typeItems[0].id,
        items: typeItems,
        displayExpr: 'text',
        valueExpr: 'id',
        readOnly: false,
      },
    },     
    {
      colSpan: 1,
      label: {
        text: 'Standort',
      },
      name: 'locationid',
      dataField: 'locationid',
      editorType: 'dxSelectBox',
      editorOptions: {
        value: appointmentData.locationid ?? locationItems[0].locationid,
        items: locationItems,
        displayExpr: 'locationname',
        valueExpr: 'locationid',
        readOnly: false,
      },
    },   
    {
      colSpan: 2,
      label: {
        text: 'Mitarbeiter',
      },
      name: 'trainerid',
      dataField: 'trainerid',
      editorType: 'dxSelectBox',
      editorOptions: {
        value: appointmentData.trainerid ?? trainerItems[0].id,
        items: trainerItems,
        displayExpr: 'FirstName',
        valueExpr: 'id',
        readOnly: false,
      },
    },     
    {
      colSpan: 2,
      colCount: 2,
      caption: "Kurs Einstellungen",
      captionTemplate: "captiontemplate",
      visible: appointmentData.typeid ? (appointmentData.typeid == 1) : (typeItems[0].id == 1),
      itemType: "group",
      name: "coursesettings",
      items: [
        {
          colSpan: 2,
          label: {
            text: 'Kurs',
          },
          name: 'courseid',
          dataField: 'courseid',
          editorType: 'dxSelectBox',
          editorOptions: {
            value: appointmentData.courseid ?? courseData.value[0].id,
            items: courseData.value,
            displayExpr: 'Subject',
            valueExpr: 'id',
            readOnly: false,
          },
        },     
        {
          colSpan: 1,
          label: {
            text: 'Max. Teilnehmerzahl',
          },
          name: 'enrolledMax',
          dataField: 'enrolledMax',
          editorType: 'dxNumberBox',
          editorOptions: {
            value: appointmentData.enrolledMax ?? 15,
            min: 1,
            format: "fixedPoint",
          },
        },
        {
          colSpan: 1,
          label: {
            text: 'Abmeldefrist (Minuten)'
          },
          name: 'deregisterLatest',
          dataField: 'deregisterLatest',
          editorType: 'dxNumberBox',
          editorOptions: {
            value: appointmentData.deregisterLatest ?? 60,
            min: 0,
            format: "fixedPoint",
            placeholder: 60,
          },
        },
      ],
    },
    {
      colSpan: 1,
      label: {
        text: 'Wiederholung',
      },
      name: 'recurrence',
      dataField: 'recurrence',
      editorType: 'dxSelectBox',
      editorOptions: {
        items: frequencies,
        displayExpr: "text",
        valueExpr: "id",
        value: appointmentData.recurrence ?? 0,
        readonly: false,
      },
    },
    {
      colSpan: 1,
      label: {
        text: "Wiederholdung bis",
      },
      name: 'recurrenceEnd',
      dataField: 'recurrenceEnd',
      editorType: 'dxDateBox',
      editorOptions: {
        type: 'date',
        disabled: appointmentData.recurrence != 1 && appointmentData.recurrence != 2,
        value: appointmentData.recurrenceEnd ?? new Date(),
        readonly: false,
      },
    },
    {
      colSpan: 2,
      label: {
        text: 'Beschreibung',
      },
      name: 'description',
      dataField: 'description',
      editorType: 'dxTextBox',
      editorOptions: {
        readOnly: false,
      },
    }, 
  ];
}

export default {

  mounted() {
    //redirect to Member App
    if(currentUser.Role == 'Customer'){
      const currentUrl = window.location.href;
      const redirecturl = currentUrl.replace("/#/sap-scheduler", "/#/dashboard");
      window.location.href = redirecturl;
    }

    //Menu Type
    fetch(apihost+'/'+currentUser.Language+'/vue/scheduler/getschedulertype/id/action')
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result);
        typeData.value = typeData.value.concat(data);
        dataLoadedBarrier.value[1] = true;
      })
      .catch((e) => { throw 'Network error: ' + e }); //error here on fetch


    //Menu Location
    locationData.value = locationData.value.concat(currentUser.Location);

    //Menu Course
    fetch(apihost+'/'+currentUser.Language+'/vue/course/list/')
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result);
        courseData.value = data;
        dataLoadedBarrier.value[2] = true;
      })
      .catch(() => { throw 'Network error' });

    //Menu Trainer
    fetch(apihost+'/'+currentUser.Language+'/vue/member/list/?case=employee&status=active')
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result);
        trainerData.value = trainerData.value.concat(data);
        trainerDataLoaded.value = true;
        dataLoadedBarrier.value[3] = true;
      })
      .catch(() => { throw 'Network error'});

    //Scheduler
    fetch(apihost+'/'+currentUser.Language+'/vue/scheduler/getscheduler/id/action')
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result);
        this.schedulerItems = data;
        this.addRecurrenceFields();
        dataLoadedBarrier.value[0] = true;
      })
      .catch((e) => { throw 'Network error: ' + e});

    fetch(`${apihost}/vue/shift/list/?employeeid=0`)
      .then(handleErrors)
      .then(response => response.json())
      .then(result => {
        shiftData = result;
        dataLoadedBarrier.value[4] = true;
      });

    //timestamp
    this.timestamp = this.printTimestamp();
  },

  unmounted() {
    dataLoadedBarrier.value = [false, false, false, false, false];
    schedulerVif.value = [true, false];
  },

  components: {
    DxScheduler,
    DxSelectBox,
    AppointmentTemplate,
    AppointmentTooltipTemplate,
    DxForm,
    DxPopup,
    DxContextMenu,
    DxLoadIndicator,
  },
  data() {
    return {
      apihost,
      views,
      title: 'Terminplan',
      currentUser,
      schedulerItems,
      currentDate,
      typeData,
      locationData,
      shiftPopupVisible,
      shiftFormData,
      typeFilter,
      locationFilter,
      currentView,
      trainerDataLoaded,
      trainerData,
      employeeFilter,
      cellContextMenuState,
      dataLoadedBarrier,
      schedulerVif,
      appointmentContextMenuState,
      schedulerObject,
      selectedCells,
      shiftFormVisible,
    };
  },
  methods: {

    dataLoaded() {
      const loaded = !dataLoadedBarrier.value.includes(false);

      // This has to be done since DxScheduler for some reason doesn't propagate updates
      // to the disabled field to the views tooltips, so yeah.... Might open a Github issue for this.
      if(loaded && !schedulerVif.value[1]) { 
        schedulerVif.value[0] = false;
        schedulerVif.value[1] = true;
        nextTick().then(() => schedulerVif.value[0] = true);
      } 

      return !loaded;
    },

    onFilterChanged() {
      let objString = `?filtertype=${typeFilter.value == 0 ? "" : typeFilter.value}` +
      `&filterlocation=${locationFilter.value == 0 ? "" : locationFilter.value}` +
      `&filteremployeeid=${employeeFilter.value == 0 ? "" : employeeFilter.value}`;

      fetch(`${apihost}/vue/scheduler/getscheduler/id/action${objString}`)
        .then(handleErrors)
        .then(response => response.json())
        .then(result => {
          this.schedulerItems = result;
          this.addRecurrenceFields();
        });

      fetch(`${apihost}/vue/shift/list/?employeeid=${employeeFilter.value}`)
        .then(response => response.json())
        .then(result => {
          shiftData = result;
          dataLoadedBarrier.value[4] = false
          nextTick().then(() => dataLoadedBarrier.value[4] = true);
        });

    },

    showToast(event, data) {
      const startDate = new Date(data.startDate).toISOString();
      const endDate = new Date(data.endDate).toISOString();

      let objString = `?text=${data.text}&filtertype=${typeFilter.value}&filterlocation=${locationFilter.value}`;

      //Data
      if(data.typeid){
        objString = objString + '&typeid=' + data.typeid;
      } else {
        objString = objString + '&typeid=';
      }

      if(data.courseid){
        objString = objString + '&courseid=' + data.courseid;
      } else {
        objString = objString + '&courseid=';
      }

      if(data.locationid){
        objString = objString + '&locationid=' + data.locationid;
      } else {
        objString = objString + '&locationid=';
      }

      if(data.trainerid){
        objString = objString + '&trainerid=' + data.trainerid;
      } else {
        objString = objString + '&trainerid=';
      }

      if(data.startDate){
        objString = objString + '&startDate=' + startDate;
      } else {
        objString = objString + '&startDate=';
      }

      if(data.endDate){
        objString = objString + '&endDate=' + endDate;
      } else {
        objString = objString + '&endDate=';
      }

      if(data.eventType){
        objString = objString + '&eventType=' + data.eventType;
      } else {
        objString = objString + '&eventType=';
      }

      if(data.locationId){
        objString = objString + '&locationId=' + data.locationId;
      } else {
        objString = objString + '&locationId=';
      }

      if(data.description){
        objString = objString + '&description=' + data.description;
      } else {
        objString = objString + '&description=';
      }

      if(data.enrolledMax){
        objString = objString + '&enrolledMax=' + data.enrolledMax;
      } else {
        objString = objString + '&enrolledMax=15';
      }

      if(data.deregisterLatest){
        objString = objString + '&deregisterLatest=' + data.deregisterLatest;
      } else { 
        objString = objString + '&deregisterLatest=60';
      }

      if(data.recurrenceException || targetedStartDate){
        console.log(targetedStartDate);
        objString = objString + '&recurrenceException=' + encodeURIComponent(data.recurrenceException ? data.recurrenceException : targetedStartDate);
        targetedStartDate = "";
      } else {
        objString = objString + '&recurrenceException=';
      }

      if(data.recurrence && data.recurrenceEnd) {
        let freq = RRule.MONTHLY;
        if(data.recurrence == 1) {
          freq = RRule.WEEKLY; 
        } 
        const ruleObj = new RRule({
          freq: freq,
          byweekday: new Date(data.startDate).getDay() - 1,
          until: new Date(data.recurrenceEnd),
          //count: 5,
        });
        objString = objString + `&recurrenceRule=${encodeURIComponent(ruleObj.toString().replace("RRULE:", ""))}`
      } else {
        objString = objString + '&recurrenceRule=empty';
      }


      fetch(apihost+'/'+currentUser.Language+'/vue/scheduler/getscheduler/'+data.id+'/'+event+objString)
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result);
          this.schedulerItems = data;
          this.addRecurrenceFields(); 
        })
        .catch((e) => { console.log(e) });

      let message = "";
      switch(event) {
        case "Added":
          message = "Termin wurde erfolgreich hinzugefügt.";
          break;
        case "Deleted":
          message = "Termin wurde erfolgreich gelöscht.";
          break;
        case "Updated":
          message = "Termin wurde erfolgreich bearbeitet.";
          break;
      }
      notify({
        message: message,
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'success', 3000);
    },


    onAppointmentUpdated(e) {
      this.showToast('Updated', e.appointmentData);
    },

    onAppointmentFormOpening(e) {
      /** @type{DxForm} **/
      let form = e.form;
      /** @type{DxPopup} **/
      let popup = e.popup;

      formInitialized = false;
      setupSchedulerForm(form);
      setupSchedulerPopup(popup);

      form.option('items', getFormItemsArray(e.appointmentData));
      form.option('validationGroup', "test");
    },

    showAddedToast(e) {
      this.showToast('Added', e.appointmentData);
    },

    showDeletedToast(e) {
      this.showToast('Deleted', e.appointmentData);
    },

    customizeDateNavigatorText(e) {
      const formatOptions = { 
        year: 'numeric', 
        month: 'numeric', 
        day: 'numeric' 
      };
      const formattedStartDate = e.startDate.toLocaleString('en', formatOptions);
      const formattedEndDate = e.endDate.toLocaleString('en', formatOptions);
      if(this.currentView === 'day' | 'timelineDay') 
        return formattedStartDate;
      if(this.currentView === 'month' ) 
        return e.startDate.toLocaleString('en', { year: 'numeric', month: 'numeric' });
      return formattedStartDate + ' - ' + formattedEndDate;
    },

    printTimestamp: function () {
      return Date.now();
    },

    addRecurrenceFields() {
      for(let i = 0; i < this.schedulerItems.length; i++){
        let item = this.schedulerItems[i];  
        if(!item.recurrenceRule) {
          item.recurrence = 0; 
          item.recurrenceEnd = new Date();
        } else {
          const ruleObj = rrulestr(item.recurrenceRule); 
          switch(ruleObj.options.freq) {
            case RRule.WEEKLY:
              item.recurrence = 1;
              break;
            case RRule.YEARLY:
              item.recurrence = 2;
              break;
            default:
              item.recurrence = 0;
              break;
          }
          item.recurrenceEnd = ruleObj.options.until;
        }

        this.schedulerItems[i] = item;
      }
    },

    addShift() {
      shiftPopupVisible.value = true;
    },

    /**
      * @param {Date} startCellDate 
      * @param {Date} endCellDate 
      * @param {Number} quad 
      *
    **/
    paintDataCell(startCellDate, endCellDate, quad){
      let startCellAdj = new Date(startCellDate);
      let endCellAdj = new Date(endCellDate);
      switch(quad){
        case 0:
          startCellAdj.setSeconds(0, 0);
          endCellAdj.setHours(endCellAdj.getHours() - 1, 15, 0, 0);
          break;
        case 1:
          startCellAdj.setMinutes(15, 0, 0);
          endCellAdj.setHours(endCellAdj.getHours() - 1, 30, 0, 0);
          break; 
        case 2:
          startCellAdj.setMinutes(30);
          endCellAdj.setHours(endCellAdj.getHours() - 1, 45, 0, 0);
          break;
        case 3:
          startCellAdj.setMinutes(45, 0, 0);
          endCellAdj.setSeconds(0, 0);
          break;
        default:
          return {};
      } 
      
      for(const shift of shiftData) {
        const startShift = new Date(shift.startts * 1000).setSeconds(0, 0);
        const endShift = new Date(shift.endts * 1000).setSeconds(0, 0);
        if(startShift.valueOf() <= startCellAdj.valueOf() && endShift.valueOf() >= endCellAdj.valueOf()){
          return { "background-color": `#${(shift.color >>> 0).toString(16).padStart(6, '0')}66` }; 
        }
      }

      return {};
    },

    getShiftPopupToolbar(){
      return [
        {
          widget: 'dxButton',
          location: 'after',
          toolbar: 'bottom',
          options: {
            text: 'Speichern',
            onClick: () => submitShiftForm(),
          },
        },
        {
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'after',
          options: {
            text: 'Abbrechen',
            onClick: () => { 
              shiftFormData.value = { 
                employeeid: employeeFilter.value ? trainerData.value[1].id : employeeFilter.value, 
                startDate: new Date().setHours(7, 0, 0, 0), 
                endDate: new Date().setHours(8, 0, 0, 0), 
                color: "#ff0000" 
              }; 
              shiftPopupVisible.value = false; 
            },
          },
        },
      ];
    },

    /**
      * @param {DxForm} form
    **/
    setupShiftForm(form){
      form.option("items", 
        [
          {
            colSpan: 1,
            label: {
              text: 'Start',
            },
            name: 'startDate',
            dataField: 'startDate',
            editorType: 'dxDateBox',
            editorOptions: {
              type: 'datetime',
              readOnly: false,
            },
          },     
          {
            colSpan: 1,
            label: {
              text: 'Ende',
            },
            name: 'endDate',
            dataField: 'endDate',
            editorType: 'dxDateBox',
            editorOptions: {
              type: 'datetime',
              readOnly: false,
            },
          },     
          {
            colSpan: 1,
            label: {
              text: 'Mitarbeiter',
            },
            name: 'employeeid',
            dataField: 'employeeid',
            editorType: 'dxSelectBox',
            editorOptions: {
              items: trainerData.value.slice(1),
              displayExpr: 'FirstName',
              valueExpr: 'id',
              readOnly: false,
            },
          },     
          {
            colSpan: 1,
            label: {
              text: 'Farbe',
            },
            name: 'color',
            dataField: 'color',
            editorType: 'dxColorBox',
            editorOptions: {
            },
          }
        ]
      );
    },

    openCellContextMenu(e) {
      cellContextMenuState.value.cellData = e.cellData;

      const shift = getShiftByCell(e.cellData.startDate, e.cellData.endDate);
      if(shift != undefined && typeFilter.value == 2) {
        cellContextMenuState.value.items[0].visible = true;
        cellContextMenuState.value.items[1].visible = true;
        cellContextMenuState.value.shift = shift;
      } else {
        cellContextMenuState.value.items[2].visible = true;
        cellContextMenuState.value.items[3].visible = true;
      }

      cellContextMenuState.value.visible = true;
    },

    contextClickDataCell(e) {
      let startDate = cellContextMenuState.value.cellData.startDate;
      let endDate = cellContextMenuState.value.cellData.endDate;

      switch(e.itemIndex) {
        case 0:
          fetch(`${apihost}/vue/shift/getshift/${cellContextMenuState.value.shift.id}/remove`)
            .then(() => {
              let ind = shiftData.findIndex((shift) => shift.id == cellContextMenuState.value.shift.id)

              dataLoadedBarrier.value[4] = false;
              shiftData.splice(ind, 1);
              nextTick().then(() => dataLoadedBarrier.value[4] = true);
              notify({
                message: "Schicht wurde gelöscht.",
                position: {
                  my: 'center top',
                  at: 'center top',
                },
              }, 'success', 3000);
            });
          break;
        case 1:
          shiftFormUpdate = true; 

          shiftFormData.value = { 
            employeeid: cellContextMenuState.value.shift.employeeid,
            startDate:  cellContextMenuState.value.shift.startts * 1000,
            endDate: cellContextMenuState.value.shift.endts * 1000,
            color: `#${(cellContextMenuState.value.shift.color >>> 0).toString(16).padStart(6, '0')}`
          }

          shiftPopupVisible.value = true; 
          break;
        case 2:
          if(selectedCells.value.length != 0){
            startDate = selectedCells.value[0].startDate;
            endDate = selectedCells.value[selectedCells.value.length - 1].endDate;
          }

          schedulerObject.value.showAppointmentPopup({ 
            startDate: startDate,
            endDate: endDate,
          }, true);
          break;
        case 3:
          shiftFormData.value = {
            startDate: cellContextMenuState.value.cellData.startDate,
            endDate: cellContextMenuState.value.cellData.endDate,
            employeeid :employeeFilter.value ? employeeFilter.value : trainerData.value[1].id,
            color: "#ff0000" 
          };
          shiftPopupVisible.value = true;
          break;
        default:
          break;
      } 

    },

    hideContext() {
      for(let i = 0; i < cellContextMenuState.value.items.length; i++) {
        cellContextMenuState.value.items[i].visible = false;
      }

      for(let i = 0; i < appointmentContextMenuState.value.items.length; i++) {
        appointmentContextMenuState.value.items[i].visible = false;
      }
    },

    openAppointmentContextMenu(e) {
      appointmentContextMenuState.value.appointment = e.appointmentData;
      appointmentContextMenuState.value.appointmentTarget = e.targetedAppointmentData;

      appointmentContextMenuState.value.items[0].visible = true;
      appointmentContextMenuState.value.items[1].visible = e.appointmentData.recurrenceRule != null;
      appointmentContextMenuState.value.items[2].visible = e.appointmentData.recurrenceRule == null;
      appointmentContextMenuState.value.items[3].visible = e.appointmentData.recurrenceRule != null;

      appointmentContextMenuState.value.visible = true;
    },

    contextClickAppointment(e) {
      const appointment = appointmentContextMenuState.value.appointment; 
      const targetedAppointment = appointmentContextMenuState.value.appointmentTarget;

      switch(e.itemIndex) {
        case 0:
          if(appointment.recurrenceRule != null) {
            const startDate = targetedAppointment.startDate;
            schedulerObject.value.deleteRecurrence(appointment, new Date(startDate), 'occurrence');   
          } else {
            schedulerObject.value.deleteAppointment(appointment);
          }
          break;
        case 1:
          schedulerObject.value.deleteAppointment(appointment);
          break;
        case 2:
          schedulerObject.value.showAppointmentPopup(appointment, false, targetedAppointment);
          break;
        case 3:
          schedulerObject.value.showAppointmentPopup(appointment, false, targetedAppointment);
          break;
        default:
          break;
      }
    }
  },
};

</script>

<style>

#schedulerId .dx-scheduler-cell-sizes-vertical {
  height: 50px
}

</style>
